/**
 * 発令判断支援ダイアログ。
 * @module app/view/dialog/EvacRecommendDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dijit/_WidgetsInTemplateMixin',
    'dijit/Dialog',
    'dijit/focus',
    'idis/util/storage/LocalStorage',
    'idis/view/dialog/_DialogContentMixin'
], function(module, declare, lang, domClass, domStyle, _WidgetsInTemplateMixin, Dialog, focus, LocalStorage, _DialogContentMixin) {
    /**
     * モーダルでないダイアログを表すCSSクラス
     * @type {string}
     * @private
     */
    var _NO_UNDERLAY_CSS_CLASS = 'is-noUnderlay';

    /**
     * 発令判断支援ダイアログ。
     * @class EvacRecommendDialog
     */
    return declare(module.id.replace(/\//g, '.'), [Dialog, _WidgetsInTemplateMixin], {
        /**
         * モーダル化するための背景を非表示にするか
         * @type {boolean}
         */
        noUnderlay: false,

        STORAGE_KEY_DIALOG_OPEN: 'evacRecommendDialogOpen',

        // ウィジェットのDOMを生成する
        buildRendering: function() {
            this.inherited(arguments);
            // ルート要素に独自クラスを追加する
            domClass.add(this.domNode, 'evacRecommendDialog');
            if (this.noUnderlay) {
                // 元が空でなければ空白を挟む
                this['class'] = this['class'] ? (this['class'] + ' ' + _NO_UNDERLAY_CSS_CLASS) : _NO_UNDERLAY_CSS_CLASS;
            }
            // 開閉状態を反映
            if (LocalStorage.get(this.STORAGE_KEY_DIALOG_OPEN) === '0') {
                this.onEvacRecommendHideClick();
            }
        },

        // ダイアログの中身を設定する
        _setContentAttr: function() {
            // 本来の処理を実施
            var dfd = this.inherited(arguments);
            // show/hide時にコンテンツ独自の有効化・無効化処理を行えるようにする
            dfd.then(lang.hitch(this, function(content) {
                if (content && content.isInstanceOf && content.isInstanceOf(_DialogContentMixin)) {
                    content.listen(this);
                }
            }));
            // 元の結果を返す
            return dfd;
        },

        // ダイアログへフォーカスする
        focus: function() {
            // モーダルの場合は通常通り処理
            if (!this.noUnderlay) {
                return this.inherited(arguments);
            }
            // 非モーダルの場合はダイアログへの強制フォーカスを無視する
            // 最後にフォーカスされたDOMノード
            var node = focus.get('curNode');
            if (node && !this._fadeOutDeferred && node.ownerDocument === this.ownerDocument) {
                do{
                    if (node === this.domNode || domClass.contains(node, 'dijitPopup')) {
                        // 強制フォーカスの前提を満たさないので通常通り処理
                        return this.inherited(arguments);
                    }
                    node = node.parentNode;
                } while (node);
                // 強制フォーカスなので何もしない
                // FIXME 厳密には後続にモーダルダイアログがいる場合そこに押し付ける必要がある
            } else {
                // 強制フォーカスの前提を満たさないので通常通り処理
                return this.inherited(arguments);
            }
        },

        /**
         * ダイアログの大きさと位置を調整する。
         */
        reposition: function() {
            domClass.add(this.domNode, 'is-reposition');
            this.resize();
            this._position();
            setTimeout(lang.hitch(this, function() {
                domClass.remove(this.domNode, 'is-reposition');
            }), 200);
        },

        onEvacRecommendShowClick: function() {
            domStyle.set(this.containerNode, 'display', '');
            domStyle.set(this.evacRecommendTableShowMarker, 'display', 'none');
            domStyle.set(this.evacRecommendTableHideMarker, 'display', '');
            // 開閉状態を保持
            LocalStorage.set(this.STORAGE_KEY_DIALOG_OPEN, '1');
        },
        onEvacRecommendHideClick: function() {
            domStyle.set(this.containerNode, 'display', 'none');
            domStyle.set(this.evacRecommendTableShowMarker, 'display', '');
            domStyle.set(this.evacRecommendTableHideMarker, 'display', 'none');
            // 開閉状態を保持
            LocalStorage.set(this.STORAGE_KEY_DIALOG_OPEN, '0');
        }

    });
});
