/**
 * ヘッダー用モジュール。
 * @module app/view/Header
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/text!./templates/TrainingHeader.html',
    'dojo/text!./dialog/templates/EvacRecommendDialog.html',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/util/storage/LocalStorage',
    'idis/view/_IdisWidgetBase',
    'idis/service/Requester',
    'app/common/consts/FunctionCd',
    'app/model/DisasterInfo',
    'app/view/dialog/EvacRecommendDialog',
    'app/view/dialog/EvacRecommendPane',
    // 以下、変数で受けないモジュール
    'idis/view/dialog/IdisDialog',
    'idis/view/form/MunicipalitySelectForm',
    'app/view/PopupAlert',
    'dijit/form/Select',
    'idis/view/form/GeocodingForm',
    'dijit/form/Form',
    'app/view/Menu',
    'app/view/form/DisasterChanger',
    // 'app/common/notifier/MainNotifier'
    'app/common/notifier/MainNotifierTop',
    'app/common/notifier/MainNotifierBottom'
], function(module, array, declare, lang, domClass, domStyle, topic, template, evacRecommendDialogTemplate,
    Locator, Router, USER_TYPE, UserInfo, LocalStorage, _IdisWidgetBase, Requester, FunctionCd, DisasterInfo,
    EvacRecommendDialog, EvacRecommendPane) {
    /**
     * ヘッダー用オブジェクト
     * @class Header
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, /** @lends app/view/Header~Header# */ {
        // テンプレート文字列
        templateString: template,

        // テンプレート文字列（発令判断支援ダイアログ）
        evacRecommendDialogTemplateString: evacRecommendDialogTemplate,

        // ルート要素のCSSクラス
        baseClass: 'index-Header',

        // 避難情報イベント
        EVAC_ORDER_BULK_DELIVERY: 'app/evacorder/EvacOrderPage::delivered',
        EVAC_ORDER_REGISTERED: 'app/evacorder/_EvacOrderRegisterPageBase::registered',
        EVAC_ORDER_UPDATED: 'app/evacorder/EvacOrderDetailPage::updated',
        EVAC_ORDER_PROVIDE: 'app/evacorder/_EvacOrderProvidePageBase::updated',
        // 避難所情報イベント
        SHELTER_BULK_DELIVERY: 'app/shelter/ShelterListPage::delivered',
        SHELTER_REGISTERED: 'app/shelter/ShelterRegisterPage::registered',
        SHELTER_BULK_REGISTERED: 'app/shelter/ShelterBulkOperatingPage::registered',
        SHELTER_UPDATED: 'app/shelter/ShelterDetailPage::updated',
        // 災害変更イベント
        DISASTER_CHANGE: 'app/view/form/DisasterChanger::updated',

        // 発令判断支援ダイアログ
        // 位置を保持するLocalStrageのキー
        STORAGE_KEY_DIALOG_TOP: 'evacRecommendDialogPositionTop',
        STORAGE_KEY_DIALOG_LEFT: 'evacRecommendDialogPositionLeft',
        // 初期位置
        DIALOG_TOP_INITIAL: 80,
        DIALOG_LEFT_INITIAL: 50,
        // 位置の最小値（この位置以下へ移動しないようにする）
        DIALOG_TOP_MIN: 70,
        DIALOG_LEFT_MIN: 0,

        /**
         * 発令判断支援ダイアログが表示されているかどうかを保持する
         */
        _evacorderDisplayFlg: false,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            this.loginIdNode.innerHTML = UserInfo.getName();

            var userName = UserInfo.getName();
            if (userName && userName.length > 15) {
                userName = userName.substring(0, 15) + '...';
            }
            this.loginIdNode.innerHTML = userName;

            var html = [];
            // ログアウト・フォーム
            html.push('<form method="POST" action="/api/auth/logout" name="logout" style="padding: 2px;">');
            // 戻り先を設定（現在のURLのpath部分）
            html.push('<input type="hidden" name="backPath" value="');
            html.push(Locator.getHref().split('?')[0]);
            html.push('">');
            html.push('<a href="javascript:document.logout.submit()">');
            html.push('ログアウト</a></form>');

            // リンク一覧の中身を置き換える
            this.logoutButton.innerHTML = html.join('');

            if (UserInfo.isAuthed()) {
                // 認証済みの場合
                // メニュー用ボタンを表示
                domStyle.set(this.menuButton, 'display', '');
                // 右上の各要素を表示
                // ヘッダコントロールを表示
                domStyle.set(this.controlHeader, 'display', '');

                if (UserInfo.getLoginMode() === '訓練') {
                    // 訓練
                    domStyle.set(this.disaster, 'display', 'none');
                    domStyle.set(this.training, 'display', '');
                } else {
                    // 実災害
                    domStyle.set(this.disaster, 'display', '');
                    domStyle.set(this.training, 'display', 'none');
                }
                domStyle.set(this.modeArea, 'display', '');

                domStyle.set(this.controlArea, 'display', '');

                // 避難・避難情報更新時、未配信情報チェック
                topic.subscribe(this.EVAC_ORDER_BULK_DELIVERY, lang.hitch(this, this.toggleDeliveredMode));
                topic.subscribe(this.EVAC_ORDER_REGISTERED, lang.hitch(this, this.toggleDeliveredMode));
                topic.subscribe(this.EVAC_ORDER_UPDATED, lang.hitch(this, this.toggleDeliveredMode));
                topic.subscribe(this.EVAC_ORDER_PROVIDE, lang.hitch(this, this.toggleDeliveredMode));
                topic.subscribe(this.EVAC_ORDER_BULK_DELIVERY, lang.hitch(this, this.initEvacRecommendDialog));
                topic.subscribe(this.EVAC_ORDER_REGISTERED, lang.hitch(this, this.initEvacRecommendDialog));
                topic.subscribe(this.EVAC_ORDER_UPDATED, lang.hitch(this, this.initEvacRecommendDialog));
                topic.subscribe(this.EVAC_ORDER_PROVIDE, lang.hitch(this, this.initEvacRecommendDialog));
                topic.subscribe(this.SHELTER_BULK_DELIVERY, lang.hitch(this, this.toggleDeliveredMode));
                topic.subscribe(this.SHELTER_REGISTERED, lang.hitch(this, this.toggleDeliveredMode));
                topic.subscribe(this.SHELTER_BULK_REGISTERED, lang.hitch(this, this.toggleDeliveredMode));
                topic.subscribe(this.SHELTER_UPDATED, lang.hitch(this, this.toggleDeliveredMode));
                // 災害切替時に災害情報を更新
                topic.subscribe(this.DISASTER_CHANGE, lang.hitch(this, this.toggleDeliveredMode));

                // 災害切替時に発令判断支援を更新
                topic.subscribe(this.DISASTER_CHANGE, lang.hitch(this, this.initEvacRecommendDialog));
            }
        },

        // DOMの準備が完了してから呼ばれる
        postCreate: function() {
            this.inherited(arguments);
        },

        startup: function(){
            this.inherited(arguments);
            // 災害名登録がPubされた際にヘッダーの災害名を切り替える
            this.own(topic.subscribe('app/view/form/DisasterSelector::registered',
                lang.hitch(this, function(payload) {
                    this.disasterSelector._setValueAttr(payload);
            })));
            // this.own(topic.subscribe(module.id + '::notifier', lang.hitch(this, this.startNotifier)));
            // this.notifier.start();
            this.notifierTop.start();
            this.notifierBottom.start();
            if (UserInfo.isAuthed()) {
                // ログイン時、Lアラート未配信情報があれば表示
                this.toggleDeliveredMode();
                // ヘッダー自動更新用のタイマーを設定(1分毎)
                setInterval(lang.hitch(this, this.toggleDeliveredMode), 1 * 60 * 1000);
            }

            // 発令判断支援
            this.initEvacRecommendDialog();
            // 通知と同じタイミングで自動更新
            this.own(topic.subscribe('app/common/notifier/MainNotifierTop::refresh',
                lang.hitch(this, function() {
                    this.initEvacRecommendDialog();
            })));
        },

        startNotifier: function(){
            // this.notifier.start();
            this.notifierTop.start();
            this.notifierBottom.start();
        },

        /**
         * 各種情報について、未配信のLアラートがある場合、ヘッダーを未配信情報モードにする
         */
        toggleDeliveredMode: function() {
            // 災害IDをDisasterInfoから取得
            var disasterId = DisasterInfo.getDisasterId();
            if(!disasterId) {
                disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            // 配信状況を確認
            Requester.get('/api/lalert/isUndelivered/', {
                query: {
                    disasterId: disasterId
                }
            }).then(lang.hitch(this, function(item) {
                // 未配信の情報があれば、ヘッダーを未配信モードにする
                // if (item.undeliveredShelterFlg || item.undeliveredEvacOrderFlg) {
                if (item.undeliveredEvacOrderFlg) {
                    domClass.add(this.domNode, 'is-undelivered');
                    domStyle.set(this.undeliveredInfo, 'display', '');
                } else {
                    domClass.remove(this.domNode, 'is-undelivered');
                    domStyle.set(this.undeliveredInfo, 'display', 'none');
                }
                // 未配信情報リンク表示初期化
                domStyle.set(this.undeliveredEvacOrder, 'display', 'none');
                domStyle.set(this.undeliveredShelter, 'display', 'none');
                // 避難情報があれば、リンクを表示
                if (item.undeliveredEvacOrderFlg) {
                    domStyle.set(this.undeliveredEvacOrder, 'display', '');
                }
                // 避難所情報があれば、リンクを表示
                // if (item.undeliveredShelterFlg) {
                //     domStyle.set(this.undeliveredShelter, 'display', '');
                // }
            }));
        },

        /**
         * 未配信情報の避難情報リンク押下
         */
        onEvacOrderLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // ユーザーに応じて、避難情報画面へ遷移
            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    // 市町村ユーザーの場合、一覧画面へ遷移
                    Router.moveTo('evacorder', {municipalityCd: UserInfo.getMunicipalityCd()});
                    break;
                case USER_TYPE.PREFECTURE:
                case USER_TYPE.REGION:
                    // 県・県民局ユーザーの場合、概況画面へ遷移
                    Router.moveTo('evacorder/admin');
                    break;
                default:
                    break;
            }
        },

        /**
         * 未配信情報の避難所情報リンク押下
         */
        onShelterLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // ユーザーに応じて、避難情報画面へ遷移
            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    // 市町村ユーザーの場合、一覧画面へ遷移
                    Router.moveTo('shelter', {municipalityCd: UserInfo.getMunicipalityCd()});
                    break;
                case USER_TYPE.PREFECTURE:
                case USER_TYPE.REGION:
                    // 県・県民局ユーザーの場合、概況画面へ遷移
                    Router.moveTo('shelter/admin');
                    break;
                default:
                    break;
            }
        },

        /**
         * タイトル文字列がクリックされた際に呼ばれ、トップ画面へ遷移させる。
         * @param {MouseEvent} evt クリック・イベント
         */
        onTitleLinkClick: function(evt) {
            // TODO トップ画面・地図画面ではタイトルのリンクを無効化した方が良い
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // トップ画面以外にいる場合はトップ画面（通常は地図画面）へ遷移
            if (Locator.getQuery().p) {
                // ページへ遷移
                Locator.pushState('?', true);
            }
        },

        /**
         * 発令判断支援一覧を取得
         */
        initEvacRecommendDialog: function() {
            var self = this;

            if (!UserInfo.hasAuthz(FunctionCd.EVACRECOMMEND)) {
                return;
            }

            var disasterId = DisasterInfo.getDisasterId();
            var userMunics = UserInfo.getMunicipalityCds();

            Requester.get('/data/evacorder/recommend/' + disasterId + '/evacRecommend.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data) {
                console.debug('避難情報発令判断支援一覧（' + this.disasterId + '）：' +
                    JSON.stringify(data));
                var wholeList = data.items;

                // 管理対象市町村でフィルターをかける
                var items = [];
                array.forEach(wholeList, function(recommend){
                    // 管理対象市町村のレコードだった場合 & 有効な情報である場合
                    if(userMunics.indexOf(recommend.municipalityCd) !== -1 && recommend.status === '0'){
                        items.push(recommend);
                    }
                });

                self.toggleEvacRecommendDialog(items);

            }), lang.hitch(this,function(error) {
                console.log(error);
                if (error.response.status === 404) {
                    var items = [];
                    self.toggleEvacRecommendDialog(items);
                } else {
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            }));
        },

        /**
         * 発令判断支援ダイアログを更新
         */
        toggleEvacRecommendDialog: function (data) {
            var self = this;
            var isOpen = (data.length === 0) ? false : true;

            if (!this._evacRecommendDialog) {
                // 初回呼び出し時にインスタンス生成
                this._evacRecommendDialog = new EvacRecommendDialog({
                    noUnderlay: true,
                    title: '避難基準超過',
                    templateString: self.evacRecommendDialogTemplateString,
                    content: new EvacRecommendPane(),
                    closable: false,
                    _endDrag: function () {
                        // ドラッグ後に表示位置を保持する
                        // 表示位置が問題になりそうな場合は位置を戻す（メニューに重なる、等）
                        if (self._evacRecommendDialog.domNode.offsetTop > self.DIALOG_TOP_MIN) {
                            LocalStorage.set(self.STORAGE_KEY_DIALOG_TOP, self._evacRecommendDialog.domNode.offsetTop);
                        } else {
                            domStyle.set(self._evacRecommendDialog.domNode, 'top', self.DIALOG_TOP_MIN + 'px');
                        }
                        if (self._evacRecommendDialog.domNode.offsetLeft > self.DIALOG_LEFT_MIN) {
                            LocalStorage.set(self.STORAGE_KEY_DIALOG_LEFT, self._evacRecommendDialog.domNode.offsetLeft);
                        } else {
                            domStyle.set(self._evacRecommendDialog.domNode, 'left', self.DIALOG_LEFT_MIN + 'px');
                        }
                    },
                });
                // 画面が破棄された際に連れて行く
                this.own(this._evacRecommendDialog);
            }
            if (!isOpen) {
                // ダイアログを閉じる
                self._evacorderDisplayFlg = false;
                this._evacRecommendDialog.hide();
            } else {
                // ダイアログを開く
                // すでにダイアログが開いている場合はリフレッシュのみ
                if (!self._evacorderDisplayFlg) {
                    self._evacorderDisplayFlg = true;
                    // ダイアログが画面中央に表示されないよう最初は非表示
                    domClass.add(self._evacRecommendDialog.domNode, 'initialEvacRecommendDialogVisibility');
                    this._evacRecommendDialog.show().then(function () {
                        // 表示位置を指定
                        var top = LocalStorage.get(self.STORAGE_KEY_DIALOG_TOP);
                        var left = LocalStorage.get(self.STORAGE_KEY_DIALOG_LEFT);
                        if (!top) {
                            top = self.DIALOG_TOP_INITIAL;
                        }
                        if (!left) {
                            left = self.DIALOG_LEFT_INITIAL;
                        }
                        domStyle.set(self._evacRecommendDialog.domNode, 'top', top + 'px');
                        domStyle.set(self._evacRecommendDialog.domNode, 'left', left + 'px');
                        // 位置移動後に表示
                        domClass.remove(self._evacRecommendDialog.domNode, 'initialEvacRecommendDialogVisibility');
                    });            
                }
                if (data) {
                    topic.publish(module.id + '::refresh', data);
                }
            }
        }

    });
});
