/**
 * 緊急通知受信確認ダイアログモジュール。
 * @module app/broadnotify/BroadnotifyReceiveDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/dom-construct',
    'dojo/date/locale',
    'dojo/text!./templates/BroadnotifyReceiveDialog.html',
    'idis/view/page/_PageBase',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    // 'idis/view/dialog/IdisDialog',
    //  'idis/view/_IdisWidgetBase',
    'app/config',
    'idis/service/GeoService',
    'app/model/Region',
    // 以下、変数で受けないモジュール
], function(module, leaflet, declare, lang, domStyle, domConstruct, locale, template, _PageBase,
    UserInfo, IdisRest, Loader, Requester, DialogChain/*, IdisDialog, _IdisWidgetBase*/) {

        // var content = declare(_IdisWidgetBase, {
        //     templateString: template,
        //     baseClass: 'drawPanel-Container',
        //     widgetsInTemplate: true
        // });

    return declare(module.id.replace(/\//g, '.'), [_PageBase/*, IdisDialog*/],
    {
        // 非モーダルとして扱う
        //noUnderlay: true,

        // テンプレート文字列
        templateString: template,
        
        constructor: function() {
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // this.inner = new content();
            // this.own(this.inner);
        },

        // postCreate: function() {
        //     this.inherited(arguments);
        //     this.set('content', this.inner);
        // },
        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        // バージョン
        _version: null,

        /**
         * フォームを設定する。
         */
        initForm: function (object) {
            this.form.reset();
            this.attachFileDowloadButton.setDisabled(false);
            this._broadnotifyDetailId = object.broadnotifyDetailId;
            this.form.set('value', object);
            this.senderName.innerHTML = object.senderName;
            this.notifyType.innerHTML = object.notifyTypeName;
            this.time.innerHTML = locale.format(new Date(object.sendTimestamp));
            this._attachFile = object.attachFile;
            if(object.attachFile.broadnotifyAtcFileId !== null) {
                this.attachFile.innerHTML = object.attachFile.attachFileName;
            } else {
                this.attachFileDowloadButton.setDisabled(true);
            }
        },
        /**
         * 音声を流す。
         */
        audio: function(notifyType) {
            var music = new Audio();
            switch (notifyType) {
                case '01':
                    music.src = '/data/sound/sound1.mp3';
                    break;
                case '02':
                    music.src = '/data/sound/sound2.mp3';
                    break;
                case '03':
                    music.src = '/data/sound/sound3.mp3';           
                    break;
                default:
                    break;
            }
            music.loop = true;
            setTimeout(function(){
                    music.play();
                }, 100);
                
            // music.play();
            this._music = music;
        },
        /**
         * 鳴動停止
         */
        onStop: function() {
            console.debug('[鳴動停止]ボタンがクリックされました。');
            this._music.pause();
        },

        /**
         * 受信確認する。
         */
        onSubmit: function() {

            try {
                console.debug('[受信確認]ボタンがクリックされました。');

                var value = this.form.get('value');
                value.broadnotifyDetailId = this._broadnotifyDetailId;

                this.emit('update', {value: value});
                } catch (e) {
                console.error(e);
            }
            // 鳴動停止もしておく。
            this.onStop();

            return false;
        },

        /**
         * 受信確認ダイアログでダウンロードボタンがクリックされた時の挙動。
         */
        onBroadnotifyDlbutton: function() {
            var download = null;
            var self = this;
            var uri = this._attachFile.attachFilePath.replace('out/', 'data/');
            var fileName = this._attachFile.attachFileName;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            }else{
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }
            console.log('file download');
            //IE対策
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                download.onclick();
            } else {
                download.click();
            }
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function (url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function () {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },

        /**
         * ダイアログを初期化する。
         */
        initDialog: function(object) {
            this.senderName.innerHTML = '';
            this.notifyType.innerHTML = '';
            this.time.innerHTML = '';
            this.attachFile.innerHTML = '';
            this.initForm(object);
        },
    });
});
