/**
 * Main画面用の通知制御（上部）
 * @module app/common/notifier/MainNotifierTop
 */

define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/dom-style',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    './NotifierTop',
    './_MainNotifierBase',
    'app/broadnotify/BroadnotifyReceiveDialog'
], function (module, declare, lang, topic, domStyle, USER_TYPE, UserInfo, NotifierTop, _MainNotifierBase) {
    return declare(module.id.replace(/\//g, '.'), [NotifierTop, _MainNotifierBase], {

        /**
         * widget開始時に呼ばれるもの, 情報取得などやる
         */
        startup: function () {
            this.inherited(arguments);
        },

        /**
         * DOM生成
         */
        buildRendering: function () {
            this.inherited(arguments);
        },

        /**
         * widgetの動作を開始するためのfunction. これを呼び出すと画面に通知し始める
         */
        start: function () {
            if (UserInfo.getId()) {
                // var regexp = /^(?!.*-c).*(?=honbu).*$/;
                // if (regexp.test(UserInfo.getId())) {
                //     // if(UserInfo.getRoleCd() === 'R02012' || UserInfo.getRoleCd() === 'R02022' ||
                //     //     UserInfo.getRoleCd() === 'R02032') {
                //     this.initReceiveDialog();
                //     domStyle.set(this.broadnotifyReceiveDialog.closeButtonNode, 'display', 'none');
                //     this.broadnotifyLoop();
                //     // }
                // }

                // 初回ロード
                this.processChronology(true, false);  // 要請以外のクロノロジを表示
                // this.processDamageReport(true);
                // this.processScheduledReport(true);
                // this.processMessage(true);

                // 定期処理開始
                this.timer = setInterval(lang.hitch(this, this.loop), this.REQUEST_INTERVAL * 60 * 1000);

                // this.processTimeline();

                // 定期処理開始
                // this.timelineTimer = setInterval(lang.hitch(this, this.timelineLoop),
                //     this.TIMELINE_REQUEST_INTERVAL * 60 * 1000);
            }
        },

        /**
         * 定期的に実施するもの
         */
        loop: function () {
            // this.processEvacRecommend(false);
            this.processChronology(false, false);  // 要請以外を表示
            // this.processDamageReport(false);
            // this.processScheduledReport(false);
            // this.processMessage(false);

            // 避難基準超過情報を自動更新
            topic.publish(module.id + '::refresh');
        },

        /**
         * 定期的に実施するもの
         * タイムラインのみ周期が異なるので別
         */
        timelineLoop: function () {
            // this.processTimeline();
        }

    });
});
