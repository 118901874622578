define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/text!./templates/EvacRecommendPane.html',
    'dojo/topic',
    'idis/consts/USER_TYPE',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/view/dialog/DialogChain',
    './_EvacRecommendPaneBase',
    'app/model/DisasterInfo',
    '../../config',
], function(module, array, declare, lang, locale, domStyle, template, topic, USER_TYPE,
    Router, UserInfo, DialogChain, _EvacRecommendPaneBase, DisasterInfo, config) {
    return declare(module.id.replace(/\//g, '.'), _EvacRecommendPaneBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'evacRecommendPane',

        /**
         * 発令判断支援表示件数
         */
        RECOMMEND_MAX_COUNT: 5,

        /**
         * 地区名表示件数
         */
        DISTRICT_MAX_COUNT: 3,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
          * 災害ID
          */
        _disasterId: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = (UserInfo.getUserType() === USER_TYPE.PREFECTURE) ? config.municInfo.prefCd :
                    ( UserInfo.getUserType() === USER_TYPE.MUNICIPALITY ) ? UserInfo.getMunicipalityCd() :
                    null;
            this._regionCd = ( UserInfo.getUserType() === USER_TYPE.REGION ) ? UserInfo.getRegionCd() : null;
            console.debug('避難情報発令判断支援の市町村コード：' + this._municipalityCd);

            this._disasterId = DisasterInfo.getDisasterId();

        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 避難基準超過情報を更新
            this.own(topic.subscribe('app/view/Header::refresh',
                lang.hitch(this, function(payload) {
                    this.initTable(payload);
            })));
            this.own(topic.subscribe('app/view/TrainingHeader::refresh',
                lang.hitch(this, function(payload) {
                    this.initTable(payload);
            })));
        },

        /**
         * テーブルを初期化します
         */
        initTable: function(payload) {
            var self = this;
            var items = payload;

            // 市町列の表示/非表示
            if (UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION) {
                domStyle.set(this.municipalityHeader, 'display', '');
            } else {
                domStyle.set(this.municipalityHeader, 'display', 'none');
            }

            var html = [''];
            if (items.length === 0) {
                domStyle.set(this.evacRecommendPanel, 'display', 'none');
            } else {
                domStyle.set(this.evacRecommendPanel, 'display', '');

                // 各発令理由に関して、最大の避難区分のデータのみ抽出
                // items = this.getMaxEvacOrderType4EachIssueReason(items);

                // 先頭n件のみ表示
                var recommendNum = items.length;
                if (recommendNum > this.RECOMMEND_MAX_COUNT) {
                    items.length = this.RECOMMEND_MAX_COUNT;
                    this.evacRecommendNum.innerHTML = 'ほか' + (recommendNum - this.RECOMMEND_MAX_COUNT) + '件';
                }

                array.forEach(items, function(item){
                    var datetimeString = self.formatDateTime(item.updTimestamp);
                    html.push('<tr>');
                    html.push('<td style="text-align: center;">');
                    html.push(datetimeString);
                    html.push('</td>');
                    if (UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION) {
                        html.push('<td style="text-align: center;">');
                    } else {
                        html.push('<td style="text-align: center; display: none;">');
                    }
                    html.push(item.municipalityName);
                    html.push('</td>');
                    html.push('<td style="text-align: center;">');
                    html.push(this.getIssueReasonType(item));
                    html.push('</td>');
                    var evacOrderTypeProperty = this.getEvacOrderType(item);
                    html.push('<td style="text-align: center;' + evacOrderTypeProperty.style + '">');
                    html.push(evacOrderTypeProperty.type + '&nbsp;基準超過');
                    html.push('</td>');
                    html.push('<td style="padding: 0.5em;"><ul style="margin: 0; padding-inline-start: 1.5em;">');
                    array.forEach(item.recommendReason, function(reason){
                        html.push('<li>' + reason.message + '</li>');
                    }, this);
                    html.push('</ul></td>');
                    html.push('<td style="padding: 0.5em;">');
                    var districtNameList = item.districtNameList;
                    var districtNum = districtNameList.length;
                    if (districtNum > this.DISTRICT_MAX_COUNT) {
                        districtNameList.length = this.DISTRICT_MAX_COUNT;
                    }
                    var districtNameTxt = districtNameList.join('、');
                    html.push(districtNameTxt);
                    if (districtNum > this.DISTRICT_MAX_COUNT) {
                        html.push('&nbsp;など');
                    }
                    html.push('<br>全' + districtNum + '地区');
                    html.push('</td>');

                    html.push('</tr>');
                }, this);

                if(this.evacRecommendTable) {
                    // 画面遷移してない場合のみ注
                    this.evacRecommendTable.innerHTML = html.join('');
                }
            }

        },

        getMaxEvacOrderType4EachIssueReason: function(items){
            // サーバサイドから返ってくる時点で、避難区分が重い順に並んでいる。
            // そのため、各発令理由について最初に取り出されたデータが、その発令理由で一番重いデータとなる。
            var sedimentFlg = false;
            var rainfallFlg = false;
            var tsunamiFlg = false;
            var stormSurgeFlg = false;
            var resultList = [];
            array.forEach(items, function(evacRecommend){
                if(evacRecommend.issueReasonType === '01' && !sedimentFlg){
                    resultList.push(evacRecommend);
                    sedimentFlg = true;
                }
                if(evacRecommend.issueReasonType === '02' && !rainfallFlg){
                    resultList.push(evacRecommend);
                    rainfallFlg = true;
                }
                if(evacRecommend.issueReasonType === '04' && !tsunamiFlg){
                    resultList.push(evacRecommend);
                    tsunamiFlg = true;
                }
                if(evacRecommend.issueReasonType === '05' && !stormSurgeFlg){
                    resultList.push(evacRecommend);
                    stormSurgeFlg = true;
                }
            });
            return resultList;
        },

        getIssueReasonType: function(item){
            var type = null;
            switch(item.issueReasonType) {
                case '01':
                    type = '土砂';
                    break;
                case '02':
                    type = '洪水';
                    break;
                case '03':
                    type = '地震';
                    break;
                case '04':
                    type = '津波';
                    break;
                case '05':
                    type = '高潮';
                    break;
                case '06':
                    type = '火災';
                    break;
                case '07':
                    type = '暴風';
                    break;
                case '08':
                    type = '火山';
                    break;
                case '09':
                    type = '国民保護';
                    break;
                case '00':
                    type = 'その他';
                    break;
            }
            return type;
        },

        getEvacOrderType: function(item){
            var evacOrderTypeProperty = {};
            switch(item.evacOrderType) {
                case '11':
                    evacOrderTypeProperty.type = '高齢者等避難';
                    evacOrderTypeProperty.style = 'color:white; background-color:#FC002E';
                    break;
                case '13':
                    evacOrderTypeProperty.type = '避難指示';
                    evacOrderTypeProperty.style = 'color:white; background-color:#AA00AA';
                    break;
                case '14':
                    evacOrderTypeProperty.type = '緊急安全確保';
                    evacOrderTypeProperty.style = 'color:white; background-color:#0C000C';
                    break;
            }
            return evacOrderTypeProperty;
        },

        onEvacRecommendShowClick: function() {
            domStyle.set(this.evacRecommendTableAll, 'display', '');
            domStyle.set(this.evacRecommendLink, 'display', '');
            domStyle.set(this.evacRecommendTableShowMarker, 'display', 'none');
            domStyle.set(this.evacRecommendTableHideMarker, 'display', '');
        },
        onEvacRecommendHideClick: function() {
            domStyle.set(this.evacRecommendTableAll, 'display', 'none');
            domStyle.set(this.evacRecommendLink, 'display', 'none');
            domStyle.set(this.evacRecommendTableShowMarker, 'display', '');
            domStyle.set(this.evacRecommendTableHideMarker, 'display', 'none');
        },

        /**
         * 'yyyy-MM-dd HH:mm' 形式に変換
         */
        formatDateTime: function(val) {
            // var timestamp = new Date(val);
            var timestamp = new Date(val);
            var dateLabel = locale.format(timestamp, {
                selector: 'date',
                datePattern: 'yyyy/MM/dd'
            });
            var timeLabel = locale.format(timestamp, {
                selector: 'time',
                timePattern: 'HH:mm'
            });
            return dateLabel + '&nbsp;' + timeLabel;
        },

        onEvacRecommendLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 時系列情報管理へ遷移
            Router.moveTo('evacrecommend');
        }
    });
});
